<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="user_uuid"
                :label="$t('user')"
                class="autocomplete-new-entity"
                @keydown.enter.prevent="submit(ruleFormRef)"
                :class="form.user_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getUserKey()"
                  v-loading="getUserKey() === 0"
                  v-model="form.user_uuid"
                  :remote-method="searchUsers"
                  :placeholder="$t('select_an_user')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchUsers()"
                  style="width: 100%"
                  @change="onUserChange"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewUserDialog = true"
                />
                <Icons
                  v-if="form.user_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/configuration/users', {
                      uuid: form.user_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="formatted_address"
                :label="$t('address')"
                @keydown.enter.prevent="submit(ruleFormRef)"
              >
                <GMapAutocomplete
                  id="googleAutoComplete"
                  class="googleAutoComplete pac-target-input"
                  :class="formattedAddressInvalid ? 'invalid' : ''"
                  v-model="form.formatted_address"
                  @place_changed="
                    ;(address = setGeolocation($event)), updateAddress(address)
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="street" :label="$t('street_and_number')">
                <ElInput
                  v-model="form.street"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="city" :label="$t('city')">
                <ElInput
                  v-model="form.city"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="postal_code" :label="$t('postal_code')">
                <ElInput
                  v-model="form.postal_code"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="country_uuid"
                :label="$t('country')"
                class="autocomplete-new-entity"
                @keydown.enter.prevent="submit(ruleFormRef)"
              >
                <el-select-v2
                  :key="getCountryKey()"
                  v-loading="getCountryKey() === 0"
                  v-model="form.country_uuid"
                  :remote-method="searchCountries"
                  :placeholder="$t('select_a_country')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchCountries(t)"
                  style="width: 100%"
                  @change="updateProvinceArray($event)"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewCountryDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="province_uuid"
                :label="$t('province')"
                @keydown.enter.prevent="submit(ruleFormRef)"
                class="autocomplete-new-entity"
              >
                <ElSelect
                  v-model="form.province_uuid"
                  :placeholder="$t('select_a_province')"
                  :autocomplete="true"
                  style="width: 100%"
                  class="no-multiple"
                  :no-data-text="$t('select_first_a_country')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in provinces"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  />
                </ElSelect>
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewProvinceDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol v-if="width > 700" :span="16" :lg="16" />
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewUserDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewUserDialog = false), createUser($event)"
        @cancel="showNewUserDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewProvinceDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_province') }}
        </span>
      </template>
      <QuickCreateProvinceDialog
        @create=";(showNewProvinceDialog = false), createProvince($event)"
        @cancel="showNewProvinceDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCountryDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_country') }}
        </span>
      </template>
      <QuickCreateCountryDialog
        @create=";(showNewCountryDialog = false), createCountry($event)"
        @cancel="showNewCountryDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_CUSTOMER from '~/graphql/customers/mutation/createCustomer.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  user_uuid: '',
  formatted_address: '',
  street: '',
  city: '',
  postal_code: '',
  province_uuid: '',
  country_uuid: '',
  country_name: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewUserDialog = ref(false)
const showNewProvinceDialog = ref(false)
const showNewCountryDialog = ref(false)
let {
  provinces,
  countriesArray,
  address,
  containerWidth,
  formattedAddressInvalid
} = getFormInitialVariables()
let { newWindowItem } = getTableInitialVariables()

const rules = {
  user_uuid: { required }
}
const v$ = useVuelidate(rules, form)

onMounted(() => {
  initialCountries()
})

const inputRules = reactive({
  user_uuid: [
    {
      required: true,
      message: t('user_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  formatted_address: [
    {
      required: true,
      message: t('address_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    if (!form.formatted_address) {
      formattedAddressInvalid.value = true
    } else {
      formattedAddressInvalid.value = false
    }
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_CUSTOMER, {
    user_uuid: form.user_uuid,
    formatted_address: form.formatted_address,
    street: form.street,
    city: form.city,
    postal_code: form.postal_code,
    province_uuid: form.province_uuid,
    country_uuid: form.country_uuid,
    latitude: form.latitude,
    longitude: form.longitude
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'createCustomer')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createCustomer ?? null)
    loading.value = false
  }
}

const updateAddress = async (address) => {
  if (address?.find((i) => i.field === 'name')?.value) {
    form.name = address.find((i) => i.field === 'name').value
    setGoogleAutocompleteInput(form, 'nameGoogleAutoComplete', 'name')
  }
  if (address?.find((i) => i.field === 'formatted_address')?.value) {
    form.formatted_address = address.find(
      (i) => i.field === 'formatted_address'
    ).value
    setGoogleAutocompleteInput(form)
  }
  if (address?.find((i) => i.field === 'street')?.value) {
    form.street = address.find((i) => i.field === 'street').value
  } else if (address?.find((i) => i.field === 'formatted_address')?.value) {
    form.street = address.find((i) => i.field === 'formatted_address').value
  }
  if (address?.find((i) => i.field === 'city')?.value) {
    form.city = address.find((i) => i.field === 'city').value
  }
  if (address?.find((i) => i.field === 'postal_code')?.value) {
    form.postal_code = address.find((i) => i.field === 'postal_code').value
  }
  if (address?.find((i) => i.field === 'province')?.value) {
    form.province_name = address.find((i) => i.field === 'province').value
  }
  if (address?.find((i) => i.field === 'country')?.value) {
    form.country_name = address.find((i) => i.field === 'country').value
    form.country_code = address.find((i) => i.field === 'country_code').value
    searchCountriesByCode(form.country_name)
    countriesArray = await getSearchCountriesByCode()
    if (countriesArray?.length) {
      form.country_uuid =
        countriesArray.find((i) => i.code === form.country_code)?.value ?? null
      if (form.country_uuid) {
        provinces.value = await searchProvincesByCountry(form.country_uuid)
        if (address?.find((i) => i.field === 'province')?.value) {
          form.province_name = address.find((i) => i.field === 'province').value
          form.province_uuid =
            provinces.value?.find((i) => i.name === form.province_name)?.uuid ??
            null
          if (!form.province_uuid) {
            createAsyncProvince(address)
          }
        }
      } else {
        getCountryAndProvinceByName(address)
      }
    } else {
      getCountryAndProvinceByName(address)
    }
  }
  if (address?.find((i) => i.field === 'latitude')?.value) {
    form.latitude = address.find((i) => i.field === 'latitude').value
  }
  if (address?.find((i) => i.field === 'longitude')?.value) {
    form.longitude = address.find((i) => i.field === 'longitude').value
  }
}
const getCountryAndProvinceByName = async (address) => {
  const provinceName = address.find((i) => i.field === 'province').value
  const province = await searchProvincesByName(provinceName)
  form.country_name = province?.country?.name
  form.country_code = province?.country?.code
  form.country_uuid = province?.country?.uuid
  if (form.country_code) {
    searchCountriesByCode(form.country_code)
    countriesArray = await getSearchCountriesByCode()
    provinces.value = await searchProvincesByCountry(form.country_uuid)
    form.province_name = address.find((i) => i.field === 'province').value
    form.province_uuid =
      provinces.value?.find((i) => i.name === form.province_name)?.uuid ?? null
  } else {
    createAsyncCountry(address)
  }
}
const updateProvinceArray = async (event) => {
  provinces.value = await searchProvincesByCountry(event)
}
const updateCountryAndProvince = async (event) => {
  searchCountries(event.name)
  provinces.value = await searchProvincesByCountry(event.uuid)
}
const createAsyncProvince = async (address) => {
  const provinceName =
    address.find((i) => i.field === 'province')?.value ?? null
  if (provinceName && form.country_uuid) {
    const province = await mutationProvince({
      name: provinceName,
      country_uuid: form.country_uuid
    })
    form.province_uuid = province.uuid
    form.province_name = province.name
    form.country_uuid = province.country?.uuid
    form.country_code = province.country?.code
    form.country_name = province.country?.name
    updateCountryAndProvince(province.country)
  }
}
const createAsyncCountry = async (address) => {
  const countryName = address.find((i) => i.field === 'country')?.value ?? null
  const countryCode =
    address.find((i) => i.field === 'country_code')?.value ?? null
  if (countryName && countryCode) {
    let country = await mutationCountry(
      { name: countryName, code: countryCode },
      t
    )
    if (country) {
      form.country_uuid = country.uuid
      form.country_code = country.code
      form.country_name = country.name
      createAsyncProvince(address)
    } else {
      country = getSearchCountriesByCode()?.find((i) => i.code === countryCode)
      if (country) {
        form.country_uuid = country?.uuid
        form.country_code = country?.code
        form.country_name = country?.name
        createAsyncProvince(address)
      }
    }
  }
}
const createProvince = async (event) => {
  if (event?.uuid) {
    form.province_uuid = event.uuid
    form.province_name = event.name
    form.country_uuid = event.country?.uuid
    form.country_code = event.country?.code
    form.country_name = event.country?.name
    updateCountryAndProvince(event.country)
  }
}
const createCountry = async (event) => {
  if (event?.uuid) {
    form.country_uuid = event.uuid
    form.country_code = event.code
    form.country_name = event.name
    updateCountryAndProvince(event)
  }
}

const createUser = (event) => {
  if (event?.uuid) {
    form.user_uuid = event.uuid
    form.user = event
    form.full_name = event.first_name + ' ' + event.last_name
    searchUsers(event.first_name, form.user, null, true)
  }
}

const onUserChange = async (uuid) => {
  if (getSearchUsers()?.length && uuid) {
    const user = getSearchUsers().find((item) => item.value === uuid)
    const results = await usersAutocomplete({
      search: user?.label?.split(' ')[0] ?? ''
    })
    if (results?.getAllUsers?.data?.find((item) => item.uuid === uuid)) {
      const item = results.getAllUsers.data.find((item) => item.uuid === uuid)
      form.first_name = item.first_name
      form.last_name = item.last_name
      form.formatted_address = item?.address?.formatted_address
      setGoogleAutocompleteInput(
        item?.address ?? { formatted_address: null }
      )
      form.street = item?.address?.street
      form.city = item?.address?.city
      form.postal_code = item?.address?.postal_code
      if (item?.address?.province) {
        createProvince(item.address.province)
      } else {
        form.country_uuid = null
        form.province_uuid = null
      }
    }
  }
}
</script>
